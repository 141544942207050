import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Helmet } from "react-helmet"
import { Layout, SEO, CJumbotron, CBreadCrumb, MicroCmsLayout, CTab } from "../../../components/_index"

// markup
const SubPage = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  return <Helmet><meta http-equiv="refresh" content={`0;URL=/restaurants/${contentId}/?contentId=${contentId}&draftKey=${draftKey}`} /></Helmet>
}

export default SubPage;
